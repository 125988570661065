.similar-units {

	position: relative;
	height: 440px;
	transition: all 0.5s ease-out;

	#similarbedssection {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		visibility: hidden;
		background-color: var(--color-grey-light);
		transition: all 0.5s ease-out;
		z-index: 3;
	}

	#similarfacilitysection {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		background-color: var(--color-grey-light);
		z-index: 2;
	}

	#similarregionsection {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		visibility: hidden;
		background-color: var(--color-grey-light);
		z-index: 4;
	}
}

.similar-units-tabs {
	margin-bottom: 20px;
	border-bottom: 1px solid var(--color-secondary);

	.tab {

		display: inline-block;
		padding: 3px 15px;
		background-color: var(--color-secondary);
		color: var(--color-white);
		cursor: pointer;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;

		@media(max-width: 560px) {
			font-size: var(--font-size-md);
			padding: 3px 5px;
		}

		&.active {
			background-color: var(--color-primary);
		}
	}

	.desktop {
		display: none;

		@media(min-width: 561px) {
			display: block;
		}
	}

	.mobile {
		display: none;


		@media(max-width: 560px) {
			display: block;
		}
	}
}