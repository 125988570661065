.regionen-v1 {
	margin-top: var(--page-margin-top);

	.norden-box {
		margin-bottom: 30px;

		img {
			height: 300px;
			object-fit: cover;

			@media (max-width: 992px) {
				height: 160px;
			}

			@media (max-width: 767px) {
				height: auto;
			}
		}
	}

	.norderney-video {
		@media (max-width: 992px) {
			height: 305px;
		}

		@media (max-width: 768px) {
			height: 233px;
		}

		@media (max-width: 480px) {
			height: 166x;
		}

		@media (min-width: 993px) {
			height: 393px;
		}

		@media (min-width: 1200px) {
			height: 473px;
		}
	}


}