.unit-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.video-btn {
		position: absolute;
		right: 30px;
		top: 15px;
		background: var(--color-white);
		padding: 6px 12px;
		border: 1px solid #6592a9;
		border-radius: 4px;

		.fa {
			margin-right: 5px;
		}

		a {
			&:hover {
				text-decoration: none;
			}
		}
	}

	.unit-title-v1 {
		@media(max-width:768px) {
			padding-top: 24px;
		}
	}

	.unit-video-v1 {
		iframe {
			width: 100%;
			height: 525px;

			@media (max-width: 991px) {
				height: 370px;
			}

			@media (max-width: 767px) {
				height: 350px;
			}

			@media (max-width: 480px) {
				height: 300px;
			}
		}
	}

	.left-col {
		padding: 0px 15px;

		@media(max-width: 1200px) {
			width: 100% !important;
		}

		@media(min-width: 1201px) {
			width: 68% !important;
		}


		@media(min-width: 1500px) {
			width: 70% !important;
		}
	}

	.right-col {

		padding: 0px 15px;

		@media(max-width: 1200px) {
			display: none;
		}

		@media(min-width: 1201px) {
			width: 32% !important;
		}


		@media(min-width: 1500px) {
			width: 30% !important;
		}
	}


	.booking-hint {
		font-size: var(--font-size-md);
		margin-top: -20px;
		padding-bottom: 10px;
	}

	.unit-book-now-v3 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 200px;
			display: none;
			margin-bottom: 57px;
		}

		&.is-visible {
			@media(min-width:992px) {
				display: block;
			}
		}
	}

	.unit-book-now-v4 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			margin-bottom: 57px;
		}

	}

}