.footer-v1 {

	.main-footer {

		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);

		.text-muted {
			color: var(--color-white) !important;

		}

		padding-left: 5vw;
		padding-right: 5vw;

		.headline {
			font-size: 20px;
			color: var(--color-white);
			text-transform: uppercase;
			font-family: var(--title-font-family-main);
		}


		.logo {
			width: 250px;
			height: auto;
			margin-top: -40px;
			padding-bottom: 20px;
		}

		.openings {

			div {
				padding-top: 10px;
			}

			.fa {
				margin-right: 10px;
				color: var(--color-white);
			}
		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
				}

				.fa {
					font-size: 20px;
					color: var(--footer-font-color);

				}
			}

		}




		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #ffc371;
			margin-right: 10px;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}

		.paymentmethods {
			width: 100%;
			text-align: right;
			margin-top: -70px;
			padding-right: 15px;

			@media (max-width: 767px) {
				margin-top: 0;
			}

			@media all and (min-width: 768px) and (max-width: 991px) {
				margin-top: -30px;
			}

			.fa {
				color: var(--color-white);
				font-size: 2.3rem;
				padding-left: 10px;

			}
		}
	}

	.copyright {
		background-color: var(--footer-copyright-bg-color);
		color: var(--footer-copyright-font-color);

		a {
			color: var(--footer-copyright-font-color);
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		.v-office-logo {
			float: left;
			padding-right: 20px;

			img {
				width: 50px;
			}
		}
	}

}

.footer-v2 {
	background-image: url(RESOURCE/img/dune_banner.jpg);
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
	color: #fff;
	background-position: 50% 75%;

	&:before {
		content: "";
		background: #254256;
		position: absolute;
		height: 100%;
		width: 100%;
		opacity: 0.8;
		display: none;
	}

	.footer-wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;


		@media (max-width: 767px) {
			flex-direction: column;
			text-align: center;
		}

		.fa-whatsapp {
			font-size: 50px;
		}

		/*.btn {
			border: 2px solid var(--color-white);
			color: var(--color-white);
			padding: 18px 25px;
			border-radius: 0;
			font-size: 14px;
			white-space: nowrap;

			i {
				font-size: 12px;
				margin-left: 5px;
			}

			&:hover {
				background-color: var(--footer-bg-color);
				border-color: var(--footer-bg-color);
			}
		}*/

		p {
			font-weight: 300;
			margin: 0;
		}
	}

	.footer-wrap-content {
		width: 70%;
		padding: 0 30px;

		@media (max-width: 767px) {
			width: 100%;
			padding: 25px 0;
		}

		h2 {
			color: var(--color-white);
			font-size: 1.4rem;
			font-weight: 600;
			font-family: var(--font-family-main);

			@media (max-width: 767px) {
				margin-bottom: 20px;
			}
		}
	}
}